import React, { createContext, useContext, useState, useEffect } from 'react';
import { useFetchNavData } from '../services/StrapiService';
import { Navigation } from '../services/StrapiTypes';
import { UseQueryResult } from '@tanstack/react-query';
import { BaseResponseData } from '../services/StrapiTypes';

// Define the type for the context value
type NavDataContextType = UseQueryResult<BaseResponseData & {
    topNav: Navigation;
}, Error> | null;

// Create context with type and initial value
const NavDataContext = createContext<NavDataContextType>(null);

// Define props type for the provider
interface NavDataProviderProps {
    children: React.ReactNode;
}

export const NavDataProvider: React.FC<NavDataProviderProps> = ({ children }) => {
    const [isInitialFetch, setIsInitialFetch] = useState(true);
    const [cachedData, setCachedData] = useState<BaseResponseData & { topNav: Navigation; } | null>(null);

    const navData = useFetchNavData({
        enabled: isInitialFetch // Only allow the query to run when isInitialFetch is true
    });

    useEffect(() => {
        if (navData.data && isInitialFetch) {
            setCachedData(navData.data);
            setIsInitialFetch(false);
        }
    }, [navData.data, isInitialFetch]);

    const value = {
        ...navData,
        data: cachedData ?? navData.data
    } as NavDataContextType;

    return (
        <NavDataContext.Provider value={value}>
            {children}
        </NavDataContext.Provider>
    );
};

// Custom hook with type safety
export const useNavData = (): NonNullable<NavDataContextType> => {
    const context = useContext(NavDataContext);
    if (!context) {
        throw new Error('useNavData must be used within a NavDataProvider');
    }
    return context;
}; 