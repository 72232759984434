import { Card, Skeleton, Box } from "@mui/material";

export function SliderInputSkeleton() {
  return (
    <Card
      sx={{
        backgroundColor: "white",
        marginTop: 1,
        boxShadow: 3,
        height: { xs: "100%", sm: "112px" },
      }}
    >
      <Box sx={{ paddingLeft: 2, paddingRight: 2 }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            gap: 2,
            paddingTop: 1,
          }}
        >
          <Skeleton
            variant="circular"
            sx={{
              width: 40,
              height: 40,
              mt: 0.25,
            }}
          />
          <Box>
            <Skeleton
              variant="text"
              width={60}
              sx={{
                fontSize: "0.75rem",
                mb: 0.5,
              }}
            />
            <Skeleton
              variant="text"
              width={120}
              sx={{
                fontSize: "1.25rem",
              }}
            />
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            mt: 0.5,
          }}
        >
          <Skeleton
            variant="rectangular"
            sx={{
              width: "100%",
              height: 40,
              borderRadius: 1,
            }}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Skeleton variant="text" width={60} />
            <Skeleton variant="text" width={80} />
          </Box>
        </Box>
      </Box>
    </Card>
  );
}

export function GraphSkeleton({ height = 300 }: { height?: number }) {
  return (
    <Box sx={{ width: { xs: "100%", md: "60%" } }}>
      <Skeleton variant="rectangular" height={height} />
    </Box>
  );
}

export function ResultsSkeleton() {
  return (
    <Box sx={{ mt: 3 }}>
      <Skeleton variant="rectangular" height={80} />
    </Box>
  );
}

export function HeaderSkeleton() {
  return (
    <>
      <Skeleton variant="text" sx={{ fontSize: "3rem", mb: 1 }} />
      <Skeleton variant="text" sx={{ fontSize: "1.2rem", mb: 2 }} />
      <Skeleton variant="text" sx={{ fontSize: "1rem", mb: 4 }} width="90%" />
    </>
  );
}
