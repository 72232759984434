import { Link } from 'react-router-dom';
import { Box, Typography } from "@mui/material";
import { Navigation } from "../services/StrapiTypes";
import { Logo } from "./Logo";
import { useLocation } from "react-router-dom";
import { useNavData } from "../context/NavDataContext";
import { styled } from '@mui/material/styles';

const NavLink = styled(Link)(({ theme }) => ({
  margin: '0 16px',
  padding: '8px 16px',
  color: 'white',
  textDecoration: 'none',
  borderRadius: '4px',
  transition: 'all 0.2s ease-in-out',
  '&:hover': {
    textDecoration: 'underline',
    textUnderlineOffset: '4px',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  }
}));

export default function TopNavbar() {
  const location = useLocation();
  const navData = useNavData();

  const navbarLayout = (children?: React.ReactNode) => (
    <Box
      display="flex"
      width="100%"
      alignItems="center"
      bgcolor="primary.main"
      px={2}
      py={1}
      height="100px"
    >
      <Box>
        <Logo />
      </Box>
      <Box sx={{ flex: 1, display: "flex", justifyContent: "start", paddingLeft: 5 }}>
        {children}
      </Box>
    </Box>
  );

  if (navData.isLoading || !navData.data) {
    return navbarLayout();
  }

  return navbarLayout(
    navData.data?.topNav.navItems.map((item: Navigation["navItems"][number]) => (
      <NavLink key={item.href} to={item.href}>
        <Typography
          fontWeight={location.pathname === item.href ? "bold" : "normal"}
          fontSize={18}
          color="white"
          textTransform="uppercase"
        >
          {item.label}
        </Typography>
      </NavLink>
    ))
  );
}
