import { useFetchAbisList } from "../../services/BackendService";
import { useState, useEffect, useMemo } from 'react';
import { Messages } from "../MessageBox";
import { Box, useTheme, Button, Typography } from "@mui/material";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField } from '@mui/material';
import FBLoader from "../FBLoader";


export default function AbisSearchTool() {
    const { data, isLoading, error } = useFetchAbisList();
    const theme = useTheme();
    const [filteredData, setFilteredData] = useState(data);
    const [notFoundIsins, setNotFoundIsins] = useState<string[]>([]);
    const [searchText, setSearchText] = useState('');

    const handleSearch = () => {
        if (!searchText.trim()) {
            setFilteredData(data);
            setNotFoundIsins([]);
            return;
        }

        const isins = searchText
            .split(/[\s,;\t]+/)
            .map(isin => isin.trim())
            .filter(isin => isin.length > 0);

        const filtered = data?.filter(item =>
            isins.some(searchIsin =>
                item.isin.toLowerCase().includes(searchIsin.toLowerCase())
            )
        );

        const foundIsins = filtered?.map(item => item.isin.toLowerCase()) ?? [];
        const notFound = isins.filter(searchIsin =>
            !foundIsins.some(foundIsin =>
                foundIsin.includes(searchIsin.toLowerCase())
            )
        );

        setFilteredData(filtered);
        setNotFoundIsins(notFound);
    };

    // Memoize the table rows to prevent re-rendering on every input change
    const memoizedTableRows = useMemo(() =>
        filteredData?.map((item) => (
            <TableRow
                key={item.isin}
                hover
                sx={{
                    '&:nth-of-type(even)': {
                        backgroundColor: "grey.50"
                    }
                }}
            >
                <TableCell>{item.isin}</TableCell>
                <TableCell>{item.skattemaessigt_hjemsted}</TableCell>
                <TableCell>{item.navn_andelsklasse}</TableCell>
                <TableCell>{item.forste_registreringsaar}</TableCell>
            </TableRow>
        )),
        [filteredData] // Only re-compute when filteredData changes
    );

    if (isLoading) return <FBLoader />;
    if (error) return <Messages.Error message="Error fetching data" />;

    return (
        <Box>
            <Box sx={{
                display: 'flex',
                alignItems: 'flex-start',
                gap: 1,
                mb: 3
            }}>
                <TextField
                    sx={{
                        width: '600px',
                        '& .MuiOutlinedInput-root': {
                            backgroundColor: theme.palette.primary.main,
                            '& textarea': {
                                resize: 'vertical',
                                overflow: 'auto'
                            }
                        }
                    }}
                    multiline
                    rows={6}
                    placeholder="Indsæt en eller flere ISIN-numre (efterlad blank for hele listen)"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    variant="outlined"
                    slotProps={{
                        input: {
                            sx: {
                                color: theme.palette.primary.contrastText,
                                borderRadius: 2
                            },
                        },
                    }}
                />
                <Button
                    variant="contained"
                    onClick={handleSearch}
                    sx={{
                        height: '40px',
                        px: 4
                    }}
                >
                    SØG
                </Button>
            </Box>

            {notFoundIsins.length > 0 && (
                <Box sx={{ mb: 3, color: 'error.main' }}>
                    <Typography variant="subtitle1" fontWeight="bold">
                        Disse ISIN numre er <u>ikke</u> på listen:
                    </Typography>
                    <Box component="pre" sx={{ mt: 1, p: 1, backgroundColor: 'error.light', borderRadius: 1 }}>
                        {notFoundIsins.join('\n')}
                    </Box>
                </Box>
            )}

            {filteredData && filteredData.length > 0 && (
                <>
                    <Typography variant="subtitle1" fontWeight="bold" sx={{ mb: 2 }}>
                        Disse ISIN numre er på listen:
                    </Typography>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow sx={{ backgroundColor: theme.palette.accent.main }}>
                                    <TableCell>ISIN</TableCell>
                                    <TableCell>Skattemæssigt Hjemsted</TableCell>
                                    <TableCell>Navn Andelsklasse</TableCell>
                                    <TableCell>Første Registreringsår</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {memoizedTableRows}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            )}
        </Box>
    );
}