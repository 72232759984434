import { Box, useTheme } from "@mui/material";
import { FBSlider } from "../FBSlider";
import { useEffect, useState } from "react";
import SavingsOutlinedIcon from "@mui/icons-material/SavingsOutlined";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import FBDatePicker from "../FBDatePicker";
import TodayOutlinedIcon from "@mui/icons-material/TodayOutlined";
import EventOutlinedIcon from "@mui/icons-material/EventOutlined";
import PercentOutlinedIcon from "@mui/icons-material/PercentOutlined";
import { SummaryBox } from "./SummaryBox";
import { CAGRGraph } from "./CAGRGraph";

export default function CAGRCalculator() {
  const [startValue, setStartValue] = useState(100);
  const [endValue, setEndValue] = useState(500);
  const [totalReturn, setTotalReturn] = useState(100);
  const [inputMethod, setInputMethod] = useState<'values' | 'return'>('return');
  const [maxMoneyValue, setMaxMoneyValue] = useState(1000);
  const [maxReturnValue, setMaxReturnValue] = useState(1000);
  const [startDate, setStartDate] = useState<Date | null>(new Date("04/18/2014"));
  const [endDate, setEndDate] = useState<Date | null>(new Date());
  const [duration, setDuration] = useState(0);
  const [cagr, setCagr] = useState(0);

  const handleInputMethodChange = (
    event: React.MouseEvent<HTMLElement>,
    newInputMethod: 'values' | 'return' | null,
  ) => {
    if (newInputMethod !== null) {
      setInputMethod(newInputMethod);
    }
  };

  const theme = useTheme();

  // First useEffect: Update totalReturn when start/end values change
  useEffect(() => {
    if (inputMethod === 'values') {
      setTotalReturn(Math.round(((endValue - startValue) / startValue) * 100));
    }
  }, [startValue, endValue, inputMethod]);

  // Second useEffect: Update endValue when totalReturn changes
  useEffect(() => {
    if (inputMethod === 'return') {
      setEndValue(Math.round(startValue * (1 + totalReturn / 100)));
    }
  }, [totalReturn, startValue, inputMethod]);

  // Update maxMoneyValue when values exceed current max
  useEffect(() => {
    const highestValue = Math.max(startValue, endValue);
    if (highestValue > maxMoneyValue) {
      setMaxMoneyValue(highestValue);
    }
  }, [startValue, endValue, maxMoneyValue]);

  // Update maxReturnValue when totalReturn exceeds current max
  useEffect(() => {
    if (totalReturn > maxReturnValue) {
      setMaxReturnValue(totalReturn);
    }
  }, [totalReturn, maxReturnValue]);

  useEffect(() => {
    const handler = setTimeout(() => {
      const yearsBetween = startDate && endDate ? (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24 * 365.25) : 0;
      setDuration(yearsBetween);
      setCagr(100 * (Math.pow(endValue / startValue, 1 / yearsBetween) - 1));
    }, 300);

    return () => clearTimeout(handler);
  }, [startDate, endDate, startValue, endValue]);

  return (
    <Box sx={{ display: "flex", my: 2 }}>
      <Box sx={{ width: "100%", height: "100%" }}>
        <Box
          sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 1 }}
        >
          <Box sx={{ flex: { xs: "1", md: "0 0 400px" }, display: "flex", flexDirection: "column" }}>
            <FBDatePicker title="Start dato" icon={TodayOutlinedIcon} value={startDate} setValue={setStartDate} />
            <FBDatePicker title="Slut dato" icon={EventOutlinedIcon} value={endDate} setValue={setEndDate} />

            <ToggleButtonGroup
              value={inputMethod}
              exclusive
              onChange={handleInputMethodChange}
              sx={{
                paddingTop: 2,
                "& .MuiToggleButton-root:not(.Mui-selected)": { color: theme.palette.secondary.main },
                "& .MuiToggleButton-root.Mui-selected": {
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.primary.contrastText,
                  "&:hover": {
                    backgroundColor: theme.palette.primary.dark,
                  }
                },
              }}
            >
              <ToggleButton value="return" sx={{ boxShadow: 3 }}>Total afkast i procent</ToggleButton>
              <ToggleButton value="values" sx={{ boxShadow: 3 }}>Start/Slut værdier</ToggleButton>
            </ToggleButtonGroup>

            {inputMethod === 'values' ? (
              <>

                <FBSlider
                  title={"Start værdi"}
                  valuePostFix=" kr."
                  min={0}
                  max={maxMoneyValue}
                  steps={5}
                  value={startValue}
                  setValue={setStartValue}
                  icon={SavingsOutlinedIcon}
                />
                <FBSlider
                  title={"Slut værdi"}
                  valuePostFix=" kr."
                  min={0}
                  max={maxMoneyValue}
                  steps={5}
                  value={endValue}
                  setValue={setEndValue}
                  icon={SavingsOutlinedIcon}
                />
              </>
            ) : (
              <FBSlider
                title={"Total afkast"}
                valuePostFix=" %"
                min={0}
                max={maxReturnValue}
                steps={1}
                value={totalReturn}
                setValue={setTotalReturn}
                icon={PercentOutlinedIcon}
              />
            )}
          </Box>
          <Box sx={{ flex: 1 }}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              {/* Summary Box */}
              <SummaryBox mainValue={`${cagr.toFixed(2)}%`}
                title={`CAGR over ${duration.toFixed(1)} år`}
                secondaryValues={[{ value: `${(totalReturn.toFixed(2))} %`, label: "Total afkast" }, { value: `${(totalReturn / duration).toFixed(2)} %`, label: "Gennemsnitligt afkast" }]} />
              {/* Graph */}
              <Box>
                <CAGRGraph startValue={startValue} totalYears={duration} interest={cagr} inputMethod={inputMethod} />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
