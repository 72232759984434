import { CalculatorLinksSkeleton } from "../components/skeletons/CalculatorLinksSkeleton";
import { useFetchCalculatorsPageData } from "../services/StrapiService";
import { CalculatorLinks } from "./homepage/CalculatorLinks";


export default function CalculatorPage() {
  const calculatorsPageQuery = useFetchCalculatorsPageData();

  return (
    <>
      {/* Calculator Links with independent loading */}
      {calculatorsPageQuery.isLoading ? (
        <CalculatorLinksSkeleton skeletonCount={5} />
      ) : (
        <CalculatorLinks
          calculatorLinks={calculatorsPageQuery.data?.calculatorLinks ?? []}
        />
      )}
    </>
  );
}
