import { Box, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { FBSlider } from "../FBSlider";
import { calculateYearsToTarget } from "../../utils/calculations";
import { v4 as uuidv4 } from "uuid";

import SavingsOutlinedIcon from "@mui/icons-material/SavingsOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import TrendingUpOutlinedIcon from "@mui/icons-material/TrendingUpOutlined";
import { formatCurrency } from "../../utils/formatters";
import TargetIcon from "../../assets/icons/Target_Flat_Icon.svg.png";
import { CompoundGraph } from "./CompoundGraph";
import { SummaryBox } from "./SummaryBox";

export function TimeToFirstMillionCalculator() {
  const [target, setTarget] = useState<number>(1000000);
  const [startValue, setStartValue] = useState<number>(0);
  const [deposit, setDeposit] = useState<number>(2000);
  const [interest, setInterest] = useState<number>(7.0);
  const [totalYears, setTotalYears] = useState<number>(30);
  const [totalDeposit, setTotalDeposit] = useState<number>(0);
  const [totalInterest, setTotalInterest] = useState<number>(0);
  const [sessionId] = useState(() => uuidv4());

  const [calculationValues, setCalculationValues] = useState({
    startValue: 0,
    deposit: 2500,
    interest: 7.0,
    target: 1000000,
  });

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const handler = setTimeout(() => {
      setCalculationValues({
        startValue,
        deposit,
        interest,
        target,
      });

      const result = calculateYearsToTarget(
        startValue,
        deposit,
        interest,
        target
      );

      setTotalYears(result.years);
      setTotalDeposit(result.totalDeposits);
      setTotalInterest(result.totalInterest);
    }, 300);
    return () => clearTimeout(handler);
  }, [startValue, deposit, interest, target, sessionId]);

  const theme = useTheme();

  const formatYearValue = (years: number) => {
    if (windowWidth > 1200) {
      // Full format for larger screens
      return `${Math.floor(years)} år og ${Math.round(
        (years % 1) * 12
      )} måneder`;
    }
    // Simplified decimal format for smaller screens
    return `${years.toFixed(1)} år`;
  };

  return (
    <Box
      sx={{
        display: "flex",
        my: 2,
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "100%",
          backgroundColor: theme.palette.getContrastText(
            theme.palette.primary.main
          ),
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            gap: 1,
          }}
        >
          <Box
            sx={{
              flex: { xs: "1", md: "0 0 400px" },
              display: "flex",
              flexDirection: "column",
            }}
          >
            <FBSlider
              title={"Målet"}
              valuePostFix=" kr."
              min={0}
              max={2000000}
              steps={1000000 / 50}
              value={target}
              setValue={setTarget}
              icon={TargetIcon}
            />
            <FBSlider
              title={"Start"}
              valuePostFix=" kr."
              min={0}
              max={1000000}
              steps={1000000 / 50}
              value={startValue}
              setValue={setStartValue}
              icon={SavingsOutlinedIcon}
            />
            <FBSlider
              title={"Månedlig indskud"}
              valuePostFix=" kr."
              min={0}
              max={10000}
              value={deposit}
              setValue={setDeposit}
              icon={CalendarMonthOutlinedIcon}
            />
            <FBSlider
              title={"Afkast"}
              valuePostFix="%"
              min={0}
              max={10}
              steps={0.1}
              value={interest}
              setValue={setInterest}
              formatter={(value: number) => value.toFixed(1)}
              icon={TrendingUpOutlinedIcon}
            />
          </Box>
          <Box sx={{ flex: 1 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              {/* Summary Box */}
              <SummaryBox
                title="Antal år"
                mainValue={formatYearValue(totalYears)}
                secondaryValues={[
                  {
                    label: "Indskudt",
                    value: `${formatCurrency(totalDeposit)} kr.`,
                    percentage: isNaN(
                      totalDeposit / (totalDeposit + totalInterest)
                    )
                      ? 0
                      : (totalDeposit / (totalDeposit + totalInterest)) * 100,
                  },
                  {
                    label: "Afkast",
                    value: `${formatCurrency(totalInterest)} kr.`,
                    percentage: isNaN(
                      totalInterest / (totalDeposit + totalInterest)
                    )
                      ? 0
                      : (totalInterest / (totalDeposit + totalInterest)) * 100,
                  },
                ]}
              />

              {/* Graph */}
              <Box>
                <CompoundGraph
                  startValue={calculationValues.startValue}
                  interest={calculationValues.interest}
                  monthlyDeposit={calculationValues.deposit}
                  totalYears={totalYears}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
