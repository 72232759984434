import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { NavDataProvider } from './context/NavDataContext';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import theme from './theme';
import Root from './Root';
import NotFoundPage from './pages/NotFoundPage';
import ArticleDetailPage from './pages/ArticleDetailPage';
import CalculatorPage from './pages/CalculatorPage';
import HomePage from './pages/homepage/HomePage';
import ContactPage from './pages/ContactPage';
import ArticlesPage from './pages/ArticlesPage';
import CookieConsent from "react-cookie-consent";
import { useEffect } from 'react';
import Button from '@mui/material/Button';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import TestPage from './components/Tools/AbisSearchTool';


declare global {
  interface Window {
    dataLayer: any[];
    gtag: (command: string, ...args: any[]) => void;
  }
}

const queryClient = new QueryClient();

// Define your routes
const router = createBrowserRouter(
  [
    {
      path: "/",
      element: <Root />,
      handle: {
        seo: {
          title: "Finansberegner.dk",
          description: "Finansielle beregninger gjort enkelt og overskueligt",
        },
      },
      children: [
        {
          path: "/",
          element: <HomePage />,
          handle: {
            seo: {
              title: "Hjem | Finansberegner.dk",
              description: "Find finansielle beregnere og artikler om økonomi",
            },
          },
        },
        {
          path: "/calculators",
          element: <CalculatorPage />,
          handle: {
            seo: {
              title: "Beregnere | Finansberegner.dk",
              description: "Brug vores finansielle beregnere til at planlægge din økonomi",
            },
          },
        },
        {
          path: "/calculators/:slug",
          element: <ArticleDetailPage />,
          handle: {
            seo: {
              title: "Beregner | Finansberegner.dk",
              description: "Få overblik over din økonomi med vores beregningsværktøjer",
            },
          },
        },
        {
          path: "/contact",
          element: <ContactPage />,
          handle: {
            seo: {
              title: "Kontakt | Finansberegner.dk",
              description: "Har du spørgsmål? Kontakt os her",
            },
          },
        },
        {
          path: "/articles",
          element: <ArticlesPage />,
          handle: {
            seo: {
              title: "Artikler | Finansberegner.dk",
              description: "Læs vores artikler om privatøkonomi, investering og finansiel planlægning",
            },
          },
        },
        {
          path: "/articles/:slug",
          element: <ArticleDetailPage />,
          handle: {
            seo: {
              title: "Artikel | Finansberegner.dk",
              description: "Få indsigt i privatøkonomi og finansiel planlægning",
            },
          },
        },
        {
          path: "/privacy-policy",
          element: <PrivacyPolicyPage />,
          handle: {
            seo: {
              title: "Privatlivspolitik | Finansberegner.dk",
              description: "Læs om vores privatlivspolitik og hvordan vi håndterer cookies",
            },
          },
        },
        {
          path: "/test",
          element: <TestPage />,
          handle: {
            seo: {
              title: "test | Finansberegner.dk",
              description: "test",
            },
          },
        },
        {
          path: "*",
          element: <NotFoundPage />,
          handle: {
            seo: {
              title: "Side ikke fundet | Finansberegner.dk",
              description: "Beklager, siden findes ikke",
            },
          },
        },
      ],
    },
  ],
  {
    future: {
      v7_relativeSplatPath: true,
      v7_fetcherPersist: true,
      v7_normalizeFormMethod: true,
      v7_partialHydration: true,
      v7_skipActionErrorRevalidation: true,
    },
  }
);

function App() {
  useEffect(() => {
    const hasConsented = localStorage.getItem("cookieConsent");
    if (hasConsented === "true") {
      initializeGA();
    }
  }, []);

  const initializeGA = () => {
    const script1 = document.createElement("script");
    script1.src = "https://www.googletagmanager.com/gtag/js?id=G-F4PTEND106";
    script1.async = true;
    document.head.appendChild(script1);

    window.dataLayer = window.dataLayer || [];
    window.gtag = function () {
      window.dataLayer.push(arguments);
    };

    window.gtag('js', new Date());
    window.gtag('config', 'G-F4PTEND106');
  };

  return (
    <QueryClientProvider client={queryClient}>
      <NavDataProvider>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <RouterProvider
              router={router}
              future={{
                v7_startTransition: true,
              }}
            />
            <CookieConsent
              location="bottom"
              buttonText="Acceptér cookies"
              cookieName="cookieConsent"
              style={{ background: theme.palette.accent.main, color: theme.palette.text.primary, padding: 6 }}
              buttonStyle={{}}
              ButtonComponent={({ children, onClick }: { children: React.ReactNode, onClick: () => void }) => (
                <Button
                  variant="contained"
                  onClick={onClick}
                  sx={{
                    fontSize: "13px",
                    padding: 1,
                    textTransform: 'none',
                    marginRight: 2,
                  }}
                >
                  Acceptér cookies
                </Button>
              )}
              expires={150}
              onAccept={() => {
                initializeGA();
                localStorage.setItem("cookieConsent", "true");
              }}
            >
              Denne hjemmeside bruger cookies til at forbedre din oplevelse. Ved at fortsætte accepterer du vores brug af cookies.{" "}
              <a
                href="/privacy-policy"
                style={{
                  color: theme.palette.text.primary,
                  textDecoration: 'underline',
                  textUnderlineOffset: '2px'
                }}
              >
                Læs mere om cookies
              </a>
            </CookieConsent>
          </LocalizationProvider>
        </ThemeProvider>
      </NavDataProvider>
    </QueryClientProvider>
  );
}

export default App;
