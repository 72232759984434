import { Box, Skeleton } from "@mui/material";

export function HeroSectionSkeleton() {
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: { xs: "732px", md: "380px" },
        flexDirection: { xs: "column", md: "row" },
      }}
    >
      <Box
        sx={{
          flex: 1,
          bgcolor: "primary.main",
          color: "primary.contrastText",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            alignItems: "center",
            padding: 2,
          }}
        >
          <Skeleton
            variant="text"
            width="80%"
            height={60}
            sx={{ bgcolor: "primary.light" }}
          />
          <Skeleton
            variant="text"
            width="60%"
            height={40}
            sx={{ bgcolor: "primary.light" }}
          />
          <Skeleton
            variant="rectangular"
            width={150}
            height={40}
            sx={{ bgcolor: "primary.light", borderRadius: 1 }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          flex: 1,
          bgcolor: "grey.200",
          minHeight: { xs: 366, md: 380 },
        }}
      >
        <Skeleton
          variant="rectangular"
          width="100%"
          height="100%"
          sx={{ transform: "none" }}
        />
      </Box>
    </Box>
  );
}
