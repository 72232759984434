import { Box, Typography } from "@mui/material";
import { Logo } from "./Logo";
import FacebookIcon from "@mui/icons-material/Facebook";
import XIcon from "@mui/icons-material/X";
import footerRawData from "../data/footer.json";
import { Footer as FooterType } from "../services/StrapiTypes";

export const Footer = () => {
  const footer = (footerRawData as { data: { footer: FooterType } }).data.footer;
  return (
    <Box
      component="footer"
      sx={{
        bgcolor: "primary.main",
        color: "primary.contrastText",
        padding: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Logo
          size={100}
          type="image"
          altText={footer.logoText.label}
          typographyProps={{ fontSize: "1rem", paddingLeft: 1 }}
        />
        <Box>
          <Typography variant="body2" align="center" fontWeight={200}>
            © 2024 Finansberegner – Beregnere gjort enkelt. Et samarbejde mellem{" "}
            <a
              href="https://moneypennyandmore.dk/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: 'inherit', textDecoration: 'underline' }}
            >
              Moneypenny & More ApS
            </a>{" "}
            og Fredsøe Consult ApS
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', maxWidth: '800px', margin: '0 auto', paddingTop: 1 }}>
            <Typography variant="subtitle2" align="center" fontWeight={200} fontSize={10}>
              De beregninger og resultater, der præsenteres på finansberegner.dk, er
              vejledende og baseret på de indtastede oplysninger. Siden og dens
              værktøjer erstatter ikke professionel økonomisk rådgivning. Brugere
              bør søge råd fra en kvalificeret rådgiver, hvis der er behov for
              præcise eller personlige anbefalinger.
            </Typography>
          </Box>
        </Box>
        <Box>
          {footer.social.map((social) => {
            let SocialIcon;
            switch (true) {
              case social.link.href.includes("facebook"):
                SocialIcon = FacebookIcon;
                break;
              case social.link.href.includes("twitter"):
                SocialIcon = XIcon;
                break;
              default:
                SocialIcon = FacebookIcon;
            }
            return (
              <a
                href={social.link.href}
                key={social.id}
                target="_blank"
                rel="noopener noreferrer"
                aria-label={`Visit our ${social.link.href.includes("facebook")
                  ? "Facebook"
                  : "X (Twitter)"
                  } page`}
              >
                <SocialIcon color="secondary" sx={{ margin: 0.5 }} />
              </a>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};
