import {
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { Navigation } from "../services/StrapiTypes"
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { Logo } from "./Logo";
import { useNavData } from "../context/NavDataContext";

export default function Sidebar({ open }: { open: boolean }) {
  const location = useLocation();
  const navData = useNavData();

  if (navData.isLoading || !navData.data) {
    return null;
  }

  return (
    <div>
      <List sx={{ padding: 0 }}>
        <ListItem key={"home"} disablePadding sx={{ backgroundColor: "primary.main" }}>
          <Logo size={150} />
        </ListItem>
        <Divider />
        {navData.data.topNav.navItems.map(
          (item: Navigation["navItems"][number], index: number) => (
            <ListItem key={item.id} disablePadding>
              <ListItemButton>
                <Link
                  to={item.href}
                  style={{
                    textDecoration: "none",
                    color: "inherit",
                    width: "100%",
                    backgroundColor: location.pathname === item.href ? "primary.main" : "transparent",
                  }}
                >
                  <ListItemText primary={item.label} primaryTypographyProps={{ fontWeight: location.pathname === item.href ? "bold" : "normal" }} />
                </Link>
              </ListItemButton>
            </ListItem>
          )
        )}
      </List>
    </div>
  );
}
