import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';

interface FBLoaderProps {
    text?: string;
}

const FBLoader: React.FC<FBLoaderProps> = ({ text = 'henter data...' }) => {
    return (
        <Box
            display="flex"
            gap={2}
            alignItems="center"
        >
            <CircularProgress color="primary" />
            <Typography variant="body1" color="text.secondary">
                {text}
            </Typography>
        </Box>
    );
};

export default FBLoader;
