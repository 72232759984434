import { Box, Typography, useTheme } from "@mui/material";

interface SummaryBoxProps {
  title: string;
  mainValue: string;
  mainSubtitle?: string;
  secondaryValues?: Array<{
    label: string;
    value: string;
    percentage?: number;
  }>;
}

export function SummaryBox({
  title,
  mainValue,
  mainSubtitle,
  secondaryValues,
}: SummaryBoxProps) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        marginTop: 1,
        py: 2,
        px: 3,
        borderRadius: 1,
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        boxShadow: `0 4px 8px rgba(0, 0, 0, 0.3), 
          inset 1px -1px 3px rgba(0, 0, 0, 0.3),
          inset -2px 2px 4px rgba(255, 255, 255, 0.3)`,
        height: { xs: "100%", sm: "112px" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          gap: { xs: 0, sm: 3 },
        }}
      >
        <Box sx={{ display: "flex", alignItems: "flex-start" }}>
          <Box>
            <Typography
              variant="caption"
              sx={{
                color: theme.palette.primary.contrastText,
                opacity: 0.8,
                textTransform: "uppercase",
              }}
            >
              {title}
            </Typography>
            <Box>
              <Typography
                variant="h4"
                fontWeight="bold"
                sx={{
                  fontSize: {
                    xs: "h4.fontSize",
                    sm: "h4.fontSize",
                  },
                  "@media screen and (min-width: 900px) and (max-width: 1000px)":
                  {
                    fontSize: "1.5rem",
                  },
                }}
              >
                {mainValue}
              </Typography>
            </Box>
            <Typography
              variant="caption"
              sx={{
                color: theme.palette.primary.contrastText,
                opacity: 0.7,
              }}
            >{mainSubtitle || '\u00A0'}</Typography>
          </Box>
        </Box>
        {secondaryValues && (
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              gap: 2,
            }}
          >
            {secondaryValues.map((item, index) => (
              <Box key={index}>
                <Typography
                  variant="caption"
                  sx={{
                    color: theme.palette.primary.contrastText,
                    opacity: 0.7,
                    textTransform: "uppercase",
                  }}
                >
                  {item.label}
                </Typography>
                <Box>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "bold",
                      color: theme.palette.primary.contrastText,
                      opacity: 0.7,
                      "@media screen and (min-width: 900px) and (max-width: 1000px)":
                      {
                        fontSize: "1rem",
                      },
                    }}
                  >
                    {item.value}
                  </Typography>
                </Box>
                {item.percentage !== undefined && (
                  <Typography
                    variant="caption"
                    sx={{
                      color: theme.palette.primary.contrastText,
                      opacity: 0.7,
                    }}
                  >
                    ({item.percentage.toFixed(1)}% af værdien)
                  </Typography>
                )}
              </Box>
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
}
