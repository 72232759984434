import { Container, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const PrivacyPolicyPage = () => {
    return (
        <Container maxWidth="md" sx={{ py: 4 }}>
            <Typography variant="h2" gutterBottom>
                Cookie- og privatlivspolitik
            </Typography>

            {/* Dataansvarlig */}
            <Typography variant="h2" gutterBottom sx={{ mt: 4, fontSize: '1.75rem' }}>
                Dataansvarlig
            </Typography>
            <Typography variant="body1" sx={{ mb: 2, whiteSpace: 'pre-line' }}>
                {`Vi er dataansvarlig for behandlingen af de personoplysninger, som vi behandler om vores kunder og samarbejdspartnere. Du finder vores kontaktoplysninger nedenfor.

                Moneypenny And More ApS
                Christian Winthers Vej 6, 9000 Aalborg
                CVR-nr.: 40421408
                
                Fredsøe Consult ApS
                Hyldehaven 78, 8520 Lystrup
                CVR-nr.: 44464055

                Det er ikke et krav, at vores virksomhed har en ekstern DPO, men hvis du har spørgsmål til behandlingen af dine personoplysninger, så kan du kontakte os via contact@finansberegner.dk.`}
            </Typography>

            {/* Behandlingsaktiviteter */}
            <Typography variant="h2" gutterBottom sx={{ mt: 4, fontSize: '1.75rem' }}>
                Behandlingsaktiviteter
            </Typography>

            <Typography variant="body1" sx={{ mb: 2 }}>
                {`Som dataansvarlig jf. GDPR, så har vi følgende behandlingsaktiviteter.`}
            </Typography>

            {/* Besøg på hjemmeside */}
            <Typography variant="h3" gutterBottom sx={{ mt: 3, fontSize: '1.5rem' }}>
                Besøg på hjemmeside
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
                Når du besøger vores hjemmeside, så anvender vi cookies for at hjemmesiden kan fungere.
            </Typography>

            {/* Kommunikation med potentielle kunder */}
            <Typography variant="h3" gutterBottom sx={{ mt: 3, fontSize: '1.5rem' }}>
                Kommunikation med potentielle kunder
            </Typography>
            <Typography variant="body1" sx={{ mb: 2, whiteSpace: 'pre-line' }}>
                {`Når du har spørgsmål til vores side, eller ønsker at høre mere om vores services, så kan du kontakte os via:`}
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
                <Link to="/contact" style={{ color: 'inherit', textDecoration: 'underline', textUnderlineOffset: '2px' }}>
                    Kontaktformular
                </Link>
            </Typography>
            <Typography variant="body1" sx={{ mb: 2, whiteSpace: 'pre-line' }}>
                {`Herigennem vil vi behandle dine personoplysninger, så vi kan indgå i en dialog med dig fx svare på spørgsmål om vores ydelser. Vi behandler kun den information, som du giver os i forbindelse med vores kommunikation.

Vi vil typisk behandle følgende almindelige oplysninger: navn, email, telefonnummer.

Vores hjemmel til at behandle disse personoplysninger er databeskyttelsesforordningens artikel 6, stk. 1 litra f.

Vi sletter vores kommunikation med dig når det står klart om du ønsker vores ydelser eller ej.

Skulle der i et særligt tilfælde opstå et behov for at opbevare dine personoplysninger i længere tid, så vil dette kunne være tilfældet.`}
            </Typography>

            {/* Kunder */}
            <Typography variant="h3" gutterBottom sx={{ mt: 3, fontSize: '1.5rem' }}>
                Kunder
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
                {`Vi har behov for at kommunikere med vores kunder, så vi sikrer os, at ydelsen leveres korrekt. Herigennem kan vi behandle oplysninger om navn, adresse, ydelser, særlige aftaler, betalingsinformationer og lignende. 

Hjemlen til at behandle disse personoplysninger er databeskyttelsesforordningens artikel 6, stk. 1 litra b.

Når ydelsen er leveret og eventuelle udestående er afsluttede, så vil vi umiddelbart herefter slette personoplysningerne.`}
            </Typography>

            {/* Databehandlere */}
            <Typography variant="h2" gutterBottom sx={{ mt: 4, fontSize: '1.75rem' }}>
                Databehandlere
            </Typography>
            <Typography variant="body1" sx={{ mb: 2, whiteSpace: 'pre-line' }}>
                {`Få kan klare alt selv, og det samme gælder os. Vi har derfor samarbejdspartnere, samt benytter os af leverandører, hvoraf nogle kan være databehandlere.

Eksterne leverandører kan eksempelvis levere systemer til at organisere vores arbejde, services, rådgivning, IT-hosting eller markedsføring.

Vi benytter følgende databehandlere:
• Google Analytics - til analyse af websitetrafik og brugeradfærd

Det er vores ansvar at sikre, at dine personoplysninger behandles ordentligt. Derfor stiller vi høje krav til vores samarbejdspartnere, og vores partnere skal garantere, at dine personoplysninger er beskyttet.

Vi indgår derfor aftaler herom med virksomheder (databehandlere), der håndterer personoplysninger på vores vegne for at højne sikkerheden af dine personoplysninger.`}
            </Typography>

            {/* Videregivelse af personoplysninger */}
            <Typography variant="h2" gutterBottom sx={{ mt: 4, fontSize: '1.75rem' }}>
                Videregivelse af personoplysninger
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
                Vi videregiver ikke dine personoplysninger til tredjemand.
            </Typography>

            {/* De registreredes rettigheder */}
            <Typography variant="h2" gutterBottom sx={{ mt: 4, fontSize: '1.75rem' }}>
                De registreredes rettigheder
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
                {`Du har efter databeskyttelsesforordningen en række rettigheder i forhold til vores behandling af oplysninger om dig.

Hvis du vil gøre brug af dine rettigheder skal du kontakte os, så vi kan hjælpe dig med dette.`}
            </Typography>

            {/* Sub-rights sections */}
            <Typography variant="h3" gutterBottom sx={{ mt: 3, fontSize: '1.5rem' }}>
                Ret til at se oplysninger (indsigtsret)
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
                Du har ret til at få indsigt i de oplysninger, som vi behandler om dig, samt en række yderligere oplysninger.
            </Typography>

            <Typography variant="h3" gutterBottom sx={{ mt: 3, fontSize: '1.5rem' }}>
                Ret til berigtigelse (rettelse)
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
                Du har ret til at få urigtige oplysninger om dig selv rettet.
            </Typography>

            <Typography variant="h3" gutterBottom sx={{ mt: 3, fontSize: '1.5rem' }}>
                Ret til sletning
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
                I særlige tilfælde har du ret til at få slettet oplysninger om dig, inden tidspunktet for vores almindelige generelle sletning indtræffer.
            </Typography>

            <Typography variant="h3" gutterBottom sx={{ mt: 3, fontSize: '1.5rem' }}>
                Ret til begrænsning af behandling
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
                {`Du har i visse tilfælde ret til at få behandlingen af dine personoplysninger begrænset. Hvis du har ret til at få begrænset behandlingen, må vi fremover kun behandle oplysningerne – bortset fra opbevaring – med dit samtykke, eller med henblik på at retskrav kan fastlægges, gøres gældende eller forsvares, eller for at beskytte en person eller vigtige samfundsinteresser.`}
            </Typography>

            <Typography variant="h3" gutterBottom sx={{ mt: 3, fontSize: '1.5rem' }}>
                Ret til indsigelse
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
                Du har i visse tilfælde ret til at gøre indsigelse mod vores ellers lovlige behandling af dine personoplysninger. Du kan også gøre indsigelse mod behandling af dine oplysninger til direkte markedsføring.
            </Typography>

            <Typography variant="h3" gutterBottom sx={{ mt: 3, fontSize: '1.5rem' }}>
                Ret til at transmittere oplysninger (dataportabilitet)
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
                Du har i visse tilfælde ret til at modtage dine personoplysninger i et struktureret, almindeligt anvendt og maskinlæsbart format samt at få overført disse personoplysninger fra én dataansvarlig til en anden uden hindring.
            </Typography>

            <Typography variant="body1" sx={{ mb: 2 }}>
                Du kan læse mere om dine rettigheder i Datatilsynets vejledning om de registreredes rettigheder, som du finder på www.datatilsynet.dk.
            </Typography>

            <Typography variant="h3" gutterBottom sx={{ mt: 3, fontSize: '1.5rem' }}>
                Tilbagetrækning af samtykke
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
                Når vores behandling af dine personoplysninger er baseret på dit samtykke, så har du ret til at trække dit samtykke tilbage.
            </Typography>

            {/* Klage til Datatilsynet */}
            <Typography variant="h2" gutterBottom sx={{ mt: 4, fontSize: '1.75rem' }}>
                Klage til Datatilsynet
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
                {`Du har ret til at indgive en klage til Datatilsynet, hvis du er utilfreds med den måde, vi behandler dine personoplysninger på. Du finder Datatilsynets kontaktoplysninger på www.datatilsynet.dk.

Vi vil generelt opfordre dig til at læse mere om GDPR, så du er opdateret på reglerne.`}
            </Typography>
        </Container>
    );
};

export default PrivacyPolicyPage; 