import { Card, useTheme } from "@mui/material";
import { LineChart } from "@mui/x-charts/LineChart";
import { useMemo } from "react";
import { calculateCompoundGrowth } from "../../utils/calculations";
import { formatCurrency } from "../../utils/formatters";
import { axisClasses } from "@mui/x-charts";

interface CAGRGraphProps {
  startValue: number;
  interest: number;
  totalYears: number;
  inputMethod: 'values' | 'return';
}

export function CAGRGraph(props: CAGRGraphProps) {
  const { startValue, interest, totalYears, inputMethod } = props;

  // Calculate compound growth data
  const compoundData = useMemo(
    () =>
      calculateCompoundGrowth(startValue, 0, interest, Math.ceil(totalYears)),
    [startValue, interest, totalYears]
  );

  // Extract values for plotting
  const years = compoundData.map((item) => `${item.year}`);
  const totalValues = compoundData.map((item) => item.currentValue);

  const theme = useTheme();

  return (
    <Card
      sx={{
        backgroundColor: theme.palette.getContrastText(
          theme.palette.primary.main
        ),
        marginTop: 1,
        borderRadius: 1,
        boxShadow: 3,
      }}
    >
      <LineChart
        series={[
          {
            label: "Total værdi",
            data: totalValues,
            color: theme.palette.primary.main,
            curve: "linear",
          },
        ]}
        height={inputMethod === 'values' ? 416.5 : 296.5}
        xAxis={[
          {
            data: years,
            scaleType: "point",
            valueFormatter: (value) => `${value} år`,
          },
        ]}
        yAxis={[
          {
            label: "Værdi",
            valueFormatter: (value) => `${formatCurrency(value, 0, 0)} kr.`,
          },
        ]}
        sx={{
          margin: 0.5,
          [`& .${axisClasses.left} .${axisClasses.label}`]: {
            transform: "translateX(-40px)",
          },
        }}
        margin={{ top: 20, bottom: 50, left: 100, right: 30 }}

        slotProps={{ legend: { hidden: true } }}
      />
    </Card>
  );
} 