import { Card, useTheme } from "@mui/material";
import { LineChart } from "@mui/x-charts/LineChart";

interface ARPComparisonGraphProps {
  yearlyValuesFund1: number[];
  yearlyValuesFund2: number[];
}

export function ARPComparisonGraph(props: ARPComparisonGraphProps) {
  const { yearlyValuesFund1, yearlyValuesFund2 } = props;

  // Create years array starting from 1
  const years = Array.from(
    { length: yearlyValuesFund1.length },
    (_, i) => `${i + 1}`
  );

  const theme = useTheme();

  return (
    <Card
      sx={{
        backgroundColor: theme.palette.getContrastText(
          theme.palette.primary.main
        ),
        marginTop: 1,
        borderRadius: 1,
        boxShadow: 3,
      }}
    >
      <LineChart
        series={[
          {
            label: "Fond 1",
            data: yearlyValuesFund1,
            color: theme.palette.primary.main,
          },
          {
            label: "Fond 2",
            data: yearlyValuesFund2,
            color: theme.palette.accent.main,
          },
        ]}
        sx={{ margin: 0.5 }}
        height={472} // 112px for each input (x3) + 8px for margin (x2 as the graph has a margin itself)
        xAxis={[
          {
            data: years,
            scaleType: "band",
            valueFormatter: (value) => `År ${value}`,
          },
        ]}
        margin={{ top: 50, bottom: 50, left: 100, right: 10 }}
        slotProps={{
          legend: {
            direction: "row",
            position: { vertical: "top", horizontal: "middle" },
          },
        }}
      />
    </Card>
  );
}
