import { SvgIconComponent } from "@mui/icons-material";
import { Box, Card, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";


interface FBDatePickerInterface {
  title: string;
  icon?: SvgIconComponent | string;
  sx?: any;
  value: Date | null;
  setValue: (value: Date | null) => void;
}

export default function FBDatePicker(props: FBDatePickerInterface) {
  const { title, icon: Icon, sx, value, setValue } = props;

  return <Card
    sx={{
      backgroundColor: "white",
      marginTop: 1,
      boxShadow: 3,
      height: { xs: "100%", sm: "112px" },
      ...sx,
    }}
  >
    <Box sx={{ paddingLeft: 2, paddingRight: 2 }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 2,
          paddingTop: 1,
        }}
      >
        {Icon && (
          <>
            {typeof Icon === "string" ? (
              <img
                src={Icon}
                alt=""
                style={{
                  width: 45,
                  height: 45,
                  filter: "grayscale(100%)", // Simple greyscale conversion
                  opacity: 0.6, // Keep the opacity to match text.secondary
                }}
              />
            ) : (
              <Icon
                sx={{
                  fontSize: 40,
                  color: "text.secondary",
                  opacity: 0.9,
                  mt: 0.25,
                }}
              />
            )}
          </>
        )}
        <Box sx={{ width: '100%' }}>
          <Typography
            variant="caption"
            sx={{
              color: "text.secondary",
              textTransform: "uppercase",
              display: "block",
            }}
          >
            {title}
          </Typography>
          <DatePicker
            value={value ? dayjs(value) : null}
            onChange={(newValue) => setValue(newValue ? newValue.toDate() : null)}
            format="DD/MM/YYYY"
            sx={{ width: '100%' }}
          />
        </Box>
      </Box>
    </Box>
  </Card>
}
