import { Box, Typography } from "@mui/material";
import { getImageProps } from "../services/StrapiHelpers";
import { Author } from "../services/StrapiTypes"; // You'll need to create/export this type

interface AuthorsListProps {
    authors: Author[];
    showAuthors: boolean;
}

export function AuthorsList({ authors, showAuthors }: AuthorsListProps) {
    if (!showAuthors || authors.length === 0) return null;

    return (
        <Box sx={{ display: "flex", flexDirection: "row", gap: { xs: 2, sm: 5 }, paddingTop: 3 }}>
            {authors.map((author) => (
                <Box key={author.id} sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: 120,
                    textAlign: 'center'
                }}>
                    <Box
                        component="img"
                        src={getImageProps(author.avatar, 'thumbnail', true).src}
                        alt={author.name}
                        sx={{
                            width: 100,
                            height: 100,
                            borderRadius: '50%',
                            objectFit: 'cover',
                            marginBottom: 1
                        }}
                    />
                    <Typography
                        variant="body2"
                        component="span"
                    >
                        {author.name}
                    </Typography>
                </Box>
            ))}
        </Box>
    );
} 