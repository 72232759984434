import { Box, Typography } from "@mui/material";
import { getImageProps } from "../../services/StrapiHelpers";
import { HeroSection as HeroSectionType } from "../../services/StrapiTypes";
import { useEffect } from "react";

interface HeroSectionProps {
  heroSection: HeroSectionType;
}

export function HeroSection({ heroSection }: HeroSectionProps) {
  const imageProps = getImageProps(heroSection.image, "medium", true);

  // Preload the image
  useEffect(() => {
    if (imageProps?.src) {
      const link = document.createElement("link");
      link.rel = "preload";
      link.as = "image";
      link.href = imageProps.src;
      document.head.appendChild(link);

      return () => {
        document.head.removeChild(link);
      };
    }
  }, [imageProps?.src]);

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: "100%",
        flexDirection: { xs: "column", md: "row" },
      }}
    >
      <Box
        sx={{
          flex: 1,
          bgcolor: "primary.main",
          color: "primary.contrastText",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            alignItems: "center",
            padding: 2,
          }}
        >
          <Typography variant="h3" align="center">
            {heroSection.heading}
          </Typography>
          <Typography variant="h4" align="center">
            {heroSection.subHeading}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          flex: 1,
        }}
      >
        <img
          src={imageProps.src}
          alt={imageProps.alt}
          style={{
            objectFit: "cover",
            width: "100%",
            height: "100%",
            display: "block",
          }}
          loading="lazy"
        />
      </Box>
    </Box>
  );
}
