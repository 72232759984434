import { Box, Typography, TypographyProps } from "@mui/material";
import logoLight from "../assets/Finansberegner_logo_bredformat_neg.png";
import logoImage from "../assets/Finansberegner_logo_bredformat_neg.png";

interface LogoProps {
  size?: number;
  text?: string;
  altText?: string;
  typographyProps?: TypographyProps;
  type?: "full" | "image";
}

export const Logo = (props: LogoProps) => {
  const { size = 300, typographyProps, text, altText, type = "full" } = props;
  return (
    <a
      href="/"
      style={{ textDecoration: "none", color: "inherit" }}
      aria-label={altText || text || "Home"}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <img
          src={type === "full" ? logoLight : logoImage}
          alt={altText || "Logo"}
          width={size}
          height={size}
          style={{ display: "block" }}
        />
        <Typography fontWeight="bold" {...typographyProps}>
          {text}
        </Typography>
      </Box>
    </a>
  );
};
