import { ApiServiceBackend } from "./ApiService";

import { useMutation, useQuery } from "@tanstack/react-query";
import { AbisList, ContactFormData, InflationData, LatestUpdatedAt } from "./BackendTypes";

export function useFetchInflationData(country: string) {
  return useQuery({
    queryKey: ["inflationData", country],
    queryFn: async () => {
      try {
        const response = await ApiServiceBackend.makeRequest<InflationData[]>(
          `/inflation?country=${country}`
        );
        return response;
      } catch (error) {
        console.error("Error fetching inflation data:", error);
        throw error;
      }
    },
  });
}


export function useSendContactForm() {
  return useMutation({
    mutationFn: async (formData: ContactFormData) => {
      const response = await ApiServiceBackend.makeRequest(
        "/contact",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-API-Key": "c78ada7c-13b8-436a-a047-fc8faf1fb004"
          },
          body: JSON.stringify(formData)
        }
      );
      return response;
    }
  });
}


export function useFetchAbisList() {
  return useQuery({
    queryKey: ["abisList"],
    queryFn: async () => {
      try {
        const response = await ApiServiceBackend.makeRequest<AbisList[]>(
          `/abis_list`
        );
        return response;
      } catch (error) {
        console.error("Error fetching abis list:", error);
        throw error;
      }
    },
  });
}

export function useFetchLatestUpdatedAt(instrument_name: string) {
  return useQuery({
    queryKey: ["latestUpdatedAt", instrument_name],
    queryFn: async () => {
      const response = await ApiServiceBackend.makeRequest<LatestUpdatedAt>(`/instruments/${instrument_name}`);
      return response;
    }
  });
}