import { StrapiImage, StrapiImageFormat } from './StrapiTypes';

const STRAPI_URL = process.env.REACT_APP_PUBLIC_HOST || 'https://strapi.finansberegner.dk';

type ImageFormatKey = 'thumbnail' | 'small' | 'medium' | 'large';

interface NextImageProps {
  src: string;
  alt: string;
  width: number;
  height: number;
  blurDataURL?: string;
  placeholder?: 'blur' | 'empty';
}

export function getImageProps(
  image: StrapiImage,
  format: ImageFormatKey = 'large',
  includeBlur: boolean = false,
  isNextImage: boolean = false
): Omit<NextImageProps, 'blurDataURL' | 'placeholder'> | NextImageProps {
  const selectedFormat: StrapiImageFormat | undefined = image.formats[format];
  const {
    url,
    width,
    height,
  } = selectedFormat || {
    url: image.url,
    width: image.width,
    height: image.height,
  };

  const imageProps: NextImageProps = {
    src: `${STRAPI_URL}${url}`,
    alt: image.alternativeText || image.name,
    width,
    height,
  };

  if (isNextImage && includeBlur && image.formats.thumbnail) {
    imageProps.blurDataURL = `${STRAPI_URL}${image.formats.thumbnail.url}`;
    imageProps.placeholder = 'blur';
  }

  return imageProps;
}
