import { Box, TextField, Typography, Container } from '@mui/material';
import { useEffect, useState } from 'react';
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useFetchAuthors } from '../services/StrapiService';
import { Messages } from '../components/MessageBox';
import { useSendContactForm } from '../services/BackendService';
import LoadingButton from '@mui/lab/LoadingButton';
import { AuthorsList } from '../components/AuthorsList';

export default function ContactPage() {
    return (
        <GoogleReCaptchaProvider reCaptchaKey="6LfTk5QqAAAAAGa1X9gT30su04YMVEmIspFIm2Wc">
            <Contact />
        </GoogleReCaptchaProvider>
    );
}

function Contact() {
    const { data: authors } = useFetchAuthors();

    const { executeRecaptcha } = useGoogleReCaptcha();
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });
    const [isValid, setIsValid] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [emailError, setEmailError] = useState('');

    const useContactForm = useSendContactForm();

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        if (!executeRecaptcha) {
            console.error('Execute recaptcha not yet available');
            return;
        }

        // Get the reCAPTCHA token
        const token = await executeRecaptcha('contact_form');

        // Handle form submission here
        try {
            useContactForm.mutate(
                {
                    ...formData,
                    subject: 'Kontakt formular',
                    recaptchaToken: token
                },
                {
                    onSuccess: () => {
                        setIsSubmitted(true);
                    }
                }
            );
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };

    const isValidEmail = (email: string) => {
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        return emailRegex.test(email);
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));

        if (name === 'email') {
            if (!value) {
                setEmailError('Email er påkrævet');
            } else if (!isValidEmail(value)) {
                setEmailError('Indtast venligst en gyldig email');
            } else {
                setEmailError('');
            }
        }
    };

    useEffect(() => {
        setIsValid(
            formData.name !== '' &&
            formData.email !== '' &&
            isValidEmail(formData.email) &&
            formData.message !== ''
        );
    }, [formData]);

    return (
        <Container maxWidth="sm">
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <Typography variant="h4" component="h1">
                    Kontakt os
                </Typography>

                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
                    <TextField
                        fullWidth
                        label="Dit navn"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        margin="normal"
                        required
                        disabled={isSubmitted}
                    />

                    <TextField
                        fullWidth
                        label="Din e-mail"
                        name="email"
                        type="email"
                        value={formData.email}
                        onChange={handleChange}
                        margin="normal"
                        required
                        disabled={isSubmitted}
                        error={!!emailError}
                        helperText={emailError}
                    />

                    <TextField
                        fullWidth
                        label="Din besked"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        margin="normal"
                        required
                        multiline
                        minRows={8}
                        sx={{
                            '& .MuiInputBase-root': {
                                whiteSpace: 'pre-wrap'
                            }
                        }}
                        disabled={isSubmitted}
                    />

                    {isSubmitted && !useContactForm.error && !useContactForm.isPending && (
                        <Messages.Success message='Tak for din besked! Vi besvarer dig så hurtigt som muligt.' />
                    )}
                    {!isSubmitted && (<LoadingButton
                        type="submit"
                        variant="contained"
                        sx={{
                            mt: 2,
                            height: 36.5  // Add fixed height matching MUI's default button height
                        }}
                        fullWidth
                        disabled={!isValid || isSubmitted}
                        loading={useContactForm.isPending}
                    >
                        {useContactForm.isPending ? "Sender..." : "Send besked"}
                    </LoadingButton>
                    )}
                    {useContactForm.error && (
                        <Messages.Error message="Ups! Noget gik galt. Prøv igen senere." />
                    )}
                </Box>

                <Typography variant="body1" sx={{ mt: 2, fontStyle: 'italic' }}>
                    Vi glæder os til at høre fra dig!
                </Typography>
                <AuthorsList authors={authors ?? []} showAuthors={true} />
            </Box>
        </Container>
    );
}
