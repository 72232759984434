import { Box, Typography, Button } from "@mui/material";
import { Link } from "react-router-dom";

export default function NotFoundPage() {
  return (
    <Box sx={{ textAlign: "center", mt: 8 }}>
      <Typography variant="h2" gutterBottom>
        404 - Siden blev ikke fundet :(
      </Typography>
      <Typography variant="body1">
        Beklager, vi kunne ikke finde den ønskede side.
      </Typography>
      <Link to="/" style={{ textDecoration: "none" }}>
        <Button variant="contained" color="primary" sx={{ mt: 2 }}>
          Gå tilbage til forsiden
        </Button>
      </Link>
    </Box>
  );
}
