import { Box, Skeleton } from "@mui/material";

export function CalculatorLinksSkeleton({
  skeletonCount = 3,
}: {
  skeletonCount?: number;
}) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
        flexWrap: "wrap",
        gap: 6,
        width: { xs: "100%" },
        margin: "0 auto",
        py: 4,
      }}
    >
      {[...Array(skeletonCount)].map((_, index) => (
        <Box
          key={`skeleton-${index}`}
          width="320px"
          height="170px"
          sx={{
            position: "relative",
            boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.1)",
            borderRadius: 2,
            overflow: "hidden",
          }}
        >
          {/* Top section */}
          <Box
            sx={{
              height: "60px",
              bgcolor: "grey.200",
            }}
          >
            <Skeleton
              variant="text"
              sx={{
                pl: 14,
                pt: 1,
                width: "70%",
              }}
            />
          </Box>

          {/* Icon skeleton */}
          <Box
            sx={{
              position: "absolute",
              left: 16,
              transform: "translateY(-50%)",
              width: "80px",
              height: "80px",
              borderRadius: "50%",
              backgroundColor: "background.paper",
              border: "3px solid",
              borderColor: "grey.200",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              zIndex: 1,
            }}
          >
            <Skeleton variant="circular" width={50} height={50} />
          </Box>

          {/* Bottom section with description skeleton */}
          <Box
            sx={{
              pl: 14,
              pr: 2,
              pt: 2,
              height: "110px",
            }}
          >
            <Skeleton variant="text" width="90%" />
            <Skeleton variant="text" width="80%" />
            <Skeleton variant="text" width="60%" />
          </Box>
        </Box>
      ))}
    </Box>
  );
}
