import { Card, useTheme } from "@mui/material";
import { BarChart } from "@mui/x-charts/BarChart";
import { useMemo } from "react";
import { calculateCompoundGrowth } from "../../utils/calculations";

interface CompoundGraphProps {
  startValue: number;
  monthlyDeposit: number;
  interest: number;
  totalYears: number;
}

export function CompoundGraph(props: CompoundGraphProps) {
  const { startValue, monthlyDeposit, interest, totalYears } = props;

  // Calculate compound growth data
  const compoundData = useMemo(
    () =>
      calculateCompoundGrowth(startValue, monthlyDeposit, interest, totalYears),
    [startValue, monthlyDeposit, interest, totalYears]
  );

  // Extract values for plotting
  const years = compoundData.map((item) => `${item.year}`);
  const totalValues = compoundData.map((item) => item.currentValue);
  const depositValues = compoundData.map((item) => item.totalDeposits);

  const theme = useTheme();

  return (
    <Card
      sx={{
        backgroundColor: theme.palette.getContrastText(
          theme.palette.primary.main
        ),
        marginTop: 1,
        borderRadius: 1,
        boxShadow: 3,
      }}
    >
      <BarChart
        series={[
          {
            label: "Total værdi",
            data: totalValues,
            color: theme.palette.primary.main,
          },
          {
            label: "Indskud",
            data: depositValues,
            color: theme.palette.accent.main,
          },
        ]}
        borderRadius={10}
        sx={{ margin: 0.5 }}
        height={352} // 112px for each input (x3) + 8px for margin (x2 as the graph has a margin itself)
        xAxis={[
          {
            data: years,
            scaleType: "band",
            valueFormatter: (value) => `${value} år`,
          },
        ]}
        margin={{ top: 50, bottom: 50, left: 100, right: 10 }}
        slotProps={{
          legend: {
            direction: "row",
            position: { vertical: "top", horizontal: "middle" },
          },
        }}
      />
    </Card>
  );
}
