import { useEffect, useState } from "react";
import { FBSlider } from "../FBSlider";
import { useFetchInflationData, useFetchLatestUpdatedAt } from "../../services/BackendService";
import { Box, Typography } from "@mui/material";
import { calculateInflation } from "../../utils/calculations";
import { SummaryBox } from "./SummaryBox";
import { formatCurrency } from "../../utils/formatters";
import { InflationGraph } from "./InflationGraph";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
import TodayOutlinedIcon from "@mui/icons-material/TodayOutlined";
import EventOutlinedIcon from "@mui/icons-material/EventOutlined";
import { CalculatorSkeleton } from "../skeletons/CalculatorSkeleton";

export function InflationCalculator() {
  const { data: inflationData, isLoading } = useFetchInflationData("DK");
  const { data: latestUpdatedAt } = useFetchLatestUpdatedAt("inflation_dk");

  // Move these default values to prevent undefined access
  const [startYear, setStartYear] = useState<number>(2000);
  const [endYear, setEndYear] = useState<number>(2023);
  const [value, setValue] = useState<number>(10000);
  // Add state for calculation results
  const [calculationValues, setCalculationValues] = useState({
    value: 0,
    startYear: 0,
    endYear: 0,
  });

  // Add state for results
  const [adjustedAmount, setAdjustedAmount] = useState<number>(0);
  const [percentageChange, setPercentageChange] = useState<number>(0);
  const [averageInflation, setAverageInflation] = useState<number>(0);

  useEffect(() => {
    if (inflationData && inflationData.length > 0) {
      setStartYear(inflationData[inflationData.length - 1].year);
      setEndYear(inflationData[0].year);
    }
  }, [inflationData]);

  useEffect(() => {
    if (!inflationData) return;

    const handler = setTimeout(() => {
      setCalculationValues({
        value,
        startYear,
        endYear,
      });

      if (value > 0) {
        const result = calculateInflation(
          startYear,
          endYear,
          value,
          inflationData
        );

        // Calculate average inflation for the selected period
        const relevantData = inflationData
          .filter(d => {
            // Include data between start and end years, regardless of order
            const minYear = Math.min(startYear, endYear);
            const maxYear = Math.max(startYear, endYear);
            return d.year >= minYear && d.year <= maxYear;
          })
          .sort((a, b) =>
            // Sort based on direction (forward or backward in time)
            startYear <= endYear ? a.year - b.year : b.year - a.year
          );

        let inflationRates: number[] = [];

        // Calculate year-over-year inflation rates
        for (let i = 0; i < relevantData.length - 1; i++) {
          const current = relevantData[i];
          const next = relevantData[i + 1];

          const percentageChange = current.index_value > 0
            ? ((next.index_value - current.index_value) / current.index_value) * 100
            : 0;

          // Invert the percentage if going backward in time
          const adjustedChange = startYear > endYear ? -percentageChange : percentageChange;
          inflationRates.push(isNaN(adjustedChange) ? 0 : adjustedChange);
        }

        const averageInflation = inflationRates.length > 0
          ? inflationRates.reduce((acc, curr) => acc + curr, 0) / inflationRates.length
          : 0;

        setAdjustedAmount(result.adjustedAmount);
        setPercentageChange(result.percentageChange);
        setAverageInflation(averageInflation);
      }
    }, 300);

    return () => clearTimeout(handler);
  }, [value, startYear, endYear, inflationData]);

  // Add loading state handling
  if (isLoading || !inflationData) {
    return <CalculatorSkeleton maxWidth="100%" inputFields={3} graphHeight={232} />;
  }

  return (
    <Box
      sx={{
        display: "flex",
        my: 2,
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            gap: 1,
          }}
        >
          <Box
            sx={{
              flex: { xs: "1", md: "0 0 400px" },
              display: "flex",
              flexDirection: "column",
            }}
          >
            <FBSlider
              title={"Start år"}
              min={inflationData[inflationData.length - 1].year}
              max={inflationData[0].year}
              editable={false}
              value={startYear}
              setValue={setStartYear}
              steps={1}
              formatter={(value) => value.toString()}
              icon={TodayOutlinedIcon}
            />
            <FBSlider
              title={"Slut år"}
              valuePostFix=""
              min={inflationData[inflationData.length - 1].year}
              max={inflationData[0].year}
              value={endYear}
              setValue={setEndYear}
              editable={false}
              steps={1}
              formatter={(value) => value.toString()}
              icon={EventOutlinedIcon}
            />
            <FBSlider
              title={"Beløb (tryk på tallet for at indtaste)"}
              valuePostFix=" kr."
              min={0}
              max={100000}
              steps={100000 / 100}
              value={value}
              setValue={setValue}
              icon={PaidOutlinedIcon}
            />
          </Box>
          <Box sx={{ flex: 1 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              {/* Summary Box */}
              <SummaryBox
                title={`${formatCurrency(calculationValues.value)} kr. i ${calculationValues.startYear
                  } svarer til`}
                mainValue={`${formatCurrency(adjustedAmount)} kr.`}
                mainSubtitle={`i ${calculationValues.endYear}`}
                secondaryValues={[
                  {
                    label: "En procentvis ændring på",
                    value: `${formatCurrency(percentageChange, 1, 1)}%`,
                  },
                  {
                    label: "En gennemsnitlig inflation",
                    value: `${formatCurrency(averageInflation, 1, 1)}%`,
                  },
                ]}
              />

              {/* Graph */}
              <Box>
                <InflationGraph
                  inflationData={inflationData}
                  startYear={calculationValues.startYear}
                  endYear={calculationValues.endYear}
                  initialValue={calculationValues.value}
                />
              </Box>
            </Box>
          </Box>
        </Box>
        {latestUpdatedAt && (
          <Box>
            <Typography variant="subtitle2" fontWeight={200} fontSize={12} paddingTop={1.5}>
              (Tal for inflation er sidst opdateret i {
                new Intl.DateTimeFormat('da-DK', {
                  month: 'long',
                  year: 'numeric'
                }
                ).format(new Date(latestUpdatedAt.last_updated_at))
              } fra <u><a href="https://www.dst.dk/" target="_blank" rel="noopener noreferrer">Danmarks Statistik</a></u>)
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}
