import { Card, Container, Box } from "@mui/material";
import {
  HeaderSkeleton,
  SliderInputSkeleton,
  GraphSkeleton,
  ResultsSkeleton,
} from "./SkeletonComponents";

interface CalculatorSkeletonProps {
  maxWidth: string | number;
  inputFields: number;
  graphHeight: number;
}

export function CalculatorSkeleton(props: CalculatorSkeletonProps) {
  const { maxWidth, inputFields, graphHeight } = props;
  return (
    <Container sx={{ display: "flex", justifyContent: "center" }}>
      <Card
        sx={{
          padding: { xs: 2, md: 4 },
          width: "100%",
          maxWidth,
          borderRadius: 1,
          boxShadow: 3,
        }}
      >
        <HeaderSkeleton />

        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            gap: 4,
          }}
        >
          <Box sx={{ width: { xs: "100%", md: "40%" } }}>
            {Array(inputFields)
              .fill(0)
              .map((_, index) => (
                <SliderInputSkeleton key={index} />
              ))}
          </Box>

          <GraphSkeleton height={graphHeight} />
        </Box>

        <ResultsSkeleton />
      </Card>
    </Container>
  );
}
