import { Box, Card, Slider, Typography, TextField } from "@mui/material";
import { SvgIconComponent } from "@mui/icons-material";
import { useState } from "react";

interface FBSliderInterface {
  min: number;
  max: number;
  steps?: number;
  value: number;
  setValue: (value: number) => void;
  title: string;
  valuePostFix?: string;
  formatter?: (value: number) => string; // Optional formatter function
  disabled?: boolean;
  editable?: boolean;
  icon?: SvgIconComponent | string; // Update to allow both icon types
  sx?: any;
}

export function FBSlider(props: FBSliderInterface) {
  const [isEditing, setIsEditing] = useState(false);
  const [tempValue, setTempValue] = useState("");
  const [internalMax, setInternalMax] = useState(props.max);

  const {
    min,
    steps = 10,
    value,
    setValue,
    title,
    valuePostFix = "",
    formatter,
    disabled = false,
    editable = true,
    icon: Icon,
    sx,
  } = props;

  // Default formatter if none is provided
  const defaultFormatter = (num: number) => {
    return new Intl.NumberFormat("de-DE").format(num); // Formats with dot separator
  };

  const formatValue = formatter || defaultFormatter; // Use passed formatter or default

  const handleChange = (event: Event, newValue: number | number[]) => {
    setValue(newValue as number);
  };

  const handleValueClick = () => {
    if (!editable) return;
    setTempValue(value.toString());
    setIsEditing(true);
  };

  const handleValueSubmit = () => {
    let newValue = Number(tempValue);
    if (!isNaN(newValue)) {
      newValue = Math.max(0, newValue);

      if (newValue > internalMax) {
        const newMax = Math.ceil(newValue / steps) * steps;
        setInternalMax(newMax);
      }
      setValue(newValue);
    }
    setIsEditing(false);
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      handleValueSubmit();
    } else if (event.key === "Escape") {
      setIsEditing(false);
    }
  };

  return (
    <Card
      sx={{
        backgroundColor: "white",
        marginTop: 1,
        boxShadow: 3,
        height: { xs: "100%", sm: "112px" },
        ...sx,
      }}
    >
      <Box sx={{ paddingLeft: 2, paddingRight: 2 }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            gap: 2,
            paddingTop: 1,
          }}
        >
          {Icon && (
            <>
              {typeof Icon === "string" ? (
                <img
                  src={Icon}
                  alt=""
                  style={{
                    width: 45,
                    height: 45,
                    filter: "grayscale(100%)", // Simple greyscale conversion
                    opacity: 0.6, // Keep the opacity to match text.secondary
                  }}
                />
              ) : (
                <Icon
                  sx={{
                    fontSize: 40,
                    color: "text.secondary",
                    opacity: 0.9,
                    mt: 0.25,
                  }}
                />
              )}
            </>
          )}
          <Box>
            <Typography
              variant="caption"
              sx={{
                color: "text.secondary",
                textTransform: "uppercase",
                display: "block",
              }}
            >
              {title}
            </Typography>
            {isEditing ? (
              <TextField
                autoFocus
                value={tempValue}
                onChange={(e) => setTempValue(e.target.value)}
                onBlur={handleValueSubmit}
                onKeyDown={handleKeyPress}
                onFocus={(e) => e.target.select()}
                sx={{
                  "& input": {
                    fontWeight: "bold",
                    fontSize: "1.25em",
                    marginLeft: -1,
                  },
                  "& .MuiOutlinedInput-root": {
                    height: "24px",
                    "& fieldset": {
                      border: "none",
                    },
                  },
                }}
              />
            ) : (
              <Typography
                variant="h6"
                onClick={handleValueClick}
                sx={{
                  fontWeight: "bold",
                  lineHeight: 1.2,
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.04)",
                  },
                }}
              >
                {formatValue(value)}
                {valuePostFix}
              </Typography>
            )}
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            mt: 0.5,
          }}
        >
          <Slider
            size="medium"
            value={value}
            onChange={handleChange}
            step={steps}
            min={min}
            max={internalMax}
            marks={[{ value: min }, { value: internalMax }]}
            disabled={disabled}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Typography sx={{ textAlign: "left" }}>
              {formatValue(min)}
              {valuePostFix}
            </Typography>
            <Typography sx={{ textAlign: "right" }}>
              {formatValue(internalMax)}
              {valuePostFix}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Card>
  );
}
