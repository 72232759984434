import { Box, Typography } from "@mui/material";
import { CalculatorLink } from "../../services/StrapiTypes";
import { getImageProps } from "../../services/StrapiHelpers";

interface CalculatorLinksProps {
  calculatorLinks: CalculatorLink[];
}

export function CalculatorLinks(props: CalculatorLinksProps) {
  const { calculatorLinks } = props;
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
        flexWrap: "wrap",
        gap: 6,
        width: { xs: "100%" },
        margin: "0 auto",
        py: 4,
      }}
    >
      {calculatorLinks.map((calculatorLink: CalculatorLink) => {
        let imageProps = { src: '', alt: '' };
        try {
          imageProps = getImageProps(
            calculatorLink.icon,
            "thumbnail",
            true
          );
        } catch (error) { }
        return (
          <a
            href={calculatorLink.link.href}
            key={`calculatorLink${calculatorLink.id}`}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <Box
              width="320px"
              height="170px"
              sx={{
                position: "relative",
                boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.1)",
                borderRadius: 2,
                overflow: "hidden",
                transition: "all 0.3s ease",
                transform: "scale(1)",
                "&:hover": {
                  boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.2)",
                  transform: "scale(1.02)",
                },
              }}
            >
              {/* Top section */}
              <Box
                sx={{
                  height: "60px",
                  bgcolor: "primary.main",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    color: "primary.contrastText",
                    lineHeight: 1.2,
                    p: 1,
                    pl: 14,
                    fontSize: 20,
                  }}
                >
                  {calculatorLink.title}
                </Typography>
              </Box>

              {/* Icon overlapping both sections */}
              <Box
                sx={{
                  position: "absolute",
                  left: 16,
                  transform: "translateY(-50%)",
                  width: "80px",
                  height: "80px",
                  borderRadius: "50%",
                  backgroundColor: calculatorLink.iconBackgroundColor
                    ? calculatorLink.iconBackgroundColor
                    : "primary.main",
                  border: "3px solid",
                  borderColor: "primary.main",
                  p: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  zIndex: 1,
                  overflow: "hidden",
                }}
              >
                {imageProps.src && (
                  <img
                    src={imageProps.src}
                    alt={imageProps.alt}
                    style={{
                      width: calculatorLink.iconWidthPx,
                      height: calculatorLink.iconHeightPx,
                      objectFit: "cover",
                    }}
                  />
                )}
              </Box>

              {/* Bottom section with description */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  pl: 14,
                  pr: 2,
                  pt: 0.5,
                  height: "110px",
                }}
              >
                <Typography variant="body2" color="text.primary">
                  {calculatorLink.description}
                </Typography>
              </Box>
            </Box>
          </a>
        );
      })}
    </Box>
  );
}
