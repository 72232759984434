import { useParams } from "react-router-dom";
import { useFetchArticleBySlug } from "../services/StrapiService";
import { Box, Container, Typography } from "@mui/material";
import { ContentBlock } from "../services/StrapiTypes";
import { BlockComponentMap } from "../components/blocks/BlockComponents";
import { CalculatorSkeleton } from "../components/skeletons/CalculatorSkeleton";
import NotFoundPage from "./NotFoundPage";
import { getImageProps } from "../services/StrapiHelpers";
import dayjs from "dayjs";
import { AuthorsList } from "../components/AuthorsList";

export default function ArticleDetailPage() {
  const { slug } = useParams();
  const articleQuery = useFetchArticleBySlug(slug || "");

  if (articleQuery.isLoading) {
    return (
      <CalculatorSkeleton maxWidth="1000px" inputFields={4} graphHeight={352} />
    );
  }

  if (articleQuery.error) return <div>Error loading calculator</div>;
  if (!articleQuery.data?.[0]) return <NotFoundPage />;

  const article = articleQuery.data[0];

  return (
    <Container sx={{ display: "flex", justifyContent: "center" }}>
      <Box>
        <Typography
          variant="h2"
          sx={{
            wordBreak: "break-word",
            hyphens: "auto",
            fontSize: {
              xs: "h3.fontSize",
              sm: "h2.fontSize"
            }
          }}
        >
          {article.title}
        </Typography>
        <Typography sx={{ marginBottom: 2 }} variant="body2" color="text.secondary">Udgivet {dayjs(article.publishedAt).locale('da').format('D. MMMM YYYY')}</Typography>
        {article.coverImage?.image && (
          <img
            {...getImageProps(article.coverImage.image, "large", true)}
            style={{ width: "100%", height: "auto", marginBottom: 2 }}
          />
        )}
        <Typography
          variant="h5"
          sx={{ marginBottom: 2 }}
        >
          {article.subheading}
        </Typography>
        <Typography
          sx={{ marginBottom: 2, color: "text.secondary" }}
        >
          {article.description}
        </Typography>
        {article.blocks?.map((block: ContentBlock, index: number) =>
          BlockComponentMap[block.__component]?.(block as any, index)
        )}
        {article.showAuthors && article.authors.length > 0 && (
          <>
            <Typography sx={{ marginTop: 4 }} variant="body1" color="text.primary">Skrevet af</Typography>
            <AuthorsList authors={article.authors} showAuthors={article.showAuthors} />
          </>
        )}
      </Box>
    </Container>
  );
}
