
import { CheckCircle, SvgIconComponent, Warning } from "@mui/icons-material";
import { Box, useTheme } from "@mui/material";

enum MessageSeverity {
  Info = "info",
  Warning = "warning",
  Success = "success",
  Error = "error",
}

/**
 * Props for the MessageBox component.
 */
interface MessageBoxProps {
  message: string;
  severity?: MessageSeverity;
  icon?: SvgIconComponent;
}

/**
 * Component for displaying an message to the user.
 *
 * @param props - Props for the MessageBox component.
 * @returns A component for displaying the error message.
 */
export function MessageBox({
  message,
  severity = MessageSeverity.Info,
  icon,
}: MessageBoxProps) {
  const theme = useTheme();
  const Icon =
    icon ||
    {
      [MessageSeverity.Warning]: Warning,
      [MessageSeverity.Success]: CheckCircle,
      [MessageSeverity.Error]: Warning,
      [MessageSeverity.Info]: null,
    }[severity];

  const color = {
    [MessageSeverity.Warning]: theme.palette.warning[600],
    [MessageSeverity.Success]: theme.palette.success[600],
    [MessageSeverity.Error]: theme.palette.error[900],
    [MessageSeverity.Info]: theme.palette.primary[600],
  }[severity];

  return (
    <Box
      sx={{
        margin: "50px 5px 20px",
        display: "flex",
        justifyContent: "center",
        color: color,
        alignItems: "center",
      }}
    >
      {Icon && <Icon sx={{ fontSize: "2em", marginRight: "15px" }} />}
      <>{message}</>
    </Box>
  );
}

export function MutationError(props: MessageBoxProps) {
  return <MessageBox {...props} severity={MessageSeverity.Error} />;
}

/**
 * Component for displaying a success message after a mutation action.
 *
 * @param props - Props for the MutationSuccess component.
 * @returns A component for displaying the success message.
 */
export function MutationSuccess(props: MessageBoxProps) {
  return <MessageBox {...props} severity={MessageSeverity.Success} />;
}

export type MessageBoxPropsWithoutSeverity = Omit<MessageBoxProps, "severity">;

export const Messages = {
  Info: (props: MessageBoxPropsWithoutSeverity) => (
    <MessageBox {...props} severity={MessageSeverity.Info} />
  ),
  Error: (props: MessageBoxPropsWithoutSeverity) => (
    <MessageBox {...props} severity={MessageSeverity.Error} />
  ),
  Success: (props: MessageBoxPropsWithoutSeverity) => (
    <MessageBox {...props} severity={MessageSeverity.Success} />
  ),
  Warning: (props: MessageBoxPropsWithoutSeverity) => (
    <MessageBox {...props} severity={MessageSeverity.Warning} />
  ),
};