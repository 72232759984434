import { alpha, createTheme, ThemeOptions } from "@mui/material/styles";
import { ThemeData } from "./services/StrapiTypes";
import themeData from "./data/theme.json";

declare module '@mui/material/styles' {
  interface TypographyVariants {
    authorName: React.CSSProperties;
  }
  interface TypographyVariantsOptions {
    authorName?: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    authorName: true;
  }
}

function createThemeOptions(themeData: ThemeData): ThemeOptions {
  // Helper function to create color variations
  const createColorVariants = (mainColor: string) => ({
    main: mainColor,
    100: alpha(mainColor, 0.1),
    200: alpha(mainColor, 0.2),
    300: alpha(mainColor, 0.3),
    400: alpha(mainColor, 0.4),
    500: alpha(mainColor, 0.5),
    600: alpha(mainColor, 0.6),
    700: alpha(mainColor, 0.7),
    800: alpha(mainColor, 0.8),
    900: alpha(mainColor, 0.9),
  });

  return {
    typography: {
      fontFamily: ["Poppins", "Arial", "sans-serif"].join(","),
      authorName: {
        fontFamily: ["Satisfy", "cursive"].join(","),
        fontSize: "1.2rem",
      },
    },
    palette: {
      primary: createColorVariants(themeData.Primary.Color),
      secondary: createColorVariants(themeData.Secondary.Color),
      background: {
        default: themeData.Background.Color,
        paper: themeData.Surface.Color,
      },
      text: {
        primary: themeData.TextPrimary.Color,
        secondary: themeData.TextSecondary.Color,
      },
      error: createColorVariants(themeData.Error.Color),
      warning: createColorVariants(themeData.Warning.Color),
      success: {
        main: themeData.Success.Color,
      },
      accent: {
        main: themeData.Accent.Color,
      },
      overlay: {
        main: themeData.Overlay.Color,
      },
      shadow: {
        main: themeData.Shadow.Color,
      },
    },
  };
}
export default createTheme(createThemeOptions(themeData.data.lightTheme));
